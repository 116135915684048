
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    isOpen(newValue) {
      if (newValue === false) {
        this.$emit('input', '')
      }
    },
  },
  methods: {
    emitValue(event) {
      this.$emit('input', event?.target?.value)
    },
  },
}
