
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: 'bg-white md:bg-transparent',
    },
  },
  data() {
    return {
      isOpen: false,
      elWidth: 'auto',
      shouldFocus: true,
    }
  },
  computed: {
    inputListeners() {
      const vm = this
      return Object.assign({}, this.$listeners, {
        input(event) {
          vm.$emit('input', event.target.value)
        },
      })
    },
  },
  watch: {
    isOpen: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            if (this.shouldFocus) {
              this.$refs.searchInput?.focus()
            }
            this.shouldFocus = true
          })
        } else if (this.elWidth === 'auto') {
          if (process.client) {
            this.$nextTick(() => {
              this.calculateInitialWidth()
            })
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.value) {
      this.shouldFocus = false
      this.isOpen = true
    }
  },
  methods: {
    onToggle(value = !this.isOpen) {
      this.isOpen = value
      this.$emit('toggle', value)
    },
    calculateInitialWidth() {
      if (this.isOpen) return
      try {
        this.elWidth = this.$el.clientWidth - 32 + 'px' // calculation needed for translatable field might be different width
      } catch (ex) {
        console.log(ex)
      }
    },
  },
}
