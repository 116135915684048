
export default {
  name: 'HeroCentered',
  props: {
    topText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
