
export default {
  // No background (image on top)
  name: 'DsRepresentativeFive',
  props: {
    showDefaultImage: {
      type: Boolean,
      default: true,
    },
    image: {
      type: Object,
      default: () => {},
    },
    logo: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    hashtags: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    fallbackImage: {
      type: String,
      default: '',
    },
  },
}
