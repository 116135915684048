import { render, staticRenderFns } from "./vulnerability-report.vue?vue&type=template&id=99d7ca8e&scoped=true"
import script from "./vulnerability-report.vue?vue&type=script&lang=js"
export * from "./vulnerability-report.vue?vue&type=script&lang=js"
import style0 from "./vulnerability-report.vue?vue&type=style&index=0&id=99d7ca8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99d7ca8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormGenerator: require('/src/components/form/generator/index.vue').default,DsHeroCentered: require('/src/components/ds/hero/Centered.vue').default,DsTabsTop: require('/src/components/ds/TabsTop.vue').default,DsSpinner: require('/src/components/ds/Spinner.vue').default,VulnerabilityReportSearch: require('/src/components/VulnerabilityReportSearch.vue').default,ViewMoreLessByLines: require('/src/components/ViewMoreLessByLines.vue').default,DsPagination: require('/src/components/ds/Pagination.vue').default,DsBaseHeading: require('/src/components/ds/base/Heading.vue').default,DsBaseText: require('/src/components/ds/base/Text.vue').default,CardsGraySimpleSlotCard: require('/src/components/cards/GraySimpleSlotCard.vue').default,ProcessSvg: require('/src/components/ProcessSvg.vue').default,Button: require('/src/components/Button.vue').default,FilterSearch: require('/src/components/filter/Search.vue').default,FilterSort: require('/src/components/filter/Sort.vue').default,CardsReport: require('/src/components/cards/Report.vue').default,DsCardsRepresentativeFive: require('/src/components/ds/cards/RepresentativeFive.vue').default,SectionScrollableItemsWrapper: require('/src/components/section/ScrollableItemsWrapper.vue').default})
