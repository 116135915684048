
export default {
  name: 'DsPagination',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    pages() {
      const pages = Array.from(Array(this.count)).map((_, index) => index + 1)

      if (this.count <= this.visible) {
        return pages
      }

      const left = Math.floor((this.visible - 1) / 2)
      const right = Math.ceil((this.visible - 1) / 2)
      const activePageIndex = pages.indexOf(this.value)
      const dotsLeft = pages[activePageIndex] - left > 1
      const dotsRight = pages[activePageIndex] + right < this.count

      const sliceTo =
        this.visible - 1 >= activePageIndex + right
          ? this.visible
          : activePageIndex + right + 1

      const sliceFrom =
        sliceTo >= this.count
          ? this.count - this.visible
          : activePageIndex - left > 0
            ? activePageIndex - left
            : 0

      const result = pages.slice(sliceFrom, sliceTo)

      if (dotsLeft) {
        result[0] = '...'
      }

      if (dotsRight) {
        result[this.visible - 1] = '...'
      }

      return result
    },
  },
  methods: {
    changePage(page) {
      if (this.disabled || page < 1 || page > this.count) {
        return
      }

      this.$emit('change', page)
    },
  },
}
