
import ClickOutside from 'vue-click-outside'

export default {
  name: 'Sort',
  directives: {
    ClickOutside,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'globals/switch',
    },
    // TODO: remove when webinars lang dropdown is finished from BE
    webinars: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOptions: false,
      selectedItemIndex: 0,
    }
  },
  computed: {
    selectedItemTitle() {
      return this.selectedItem?.title || ''
    },
  },
  mounted() {
    this.selectedItemIndex =
      this.items.findIndex((item) => item?.code === this.selectedItem?.code) ||
      0
  },
  methods: {
    onSelect(item, index) {
      if (item.code === this.selectedItem.code) return
      this.$emit('onSelect', item)
      this.showOptions = false
      this.selectedItemIndex = index
    },
    onArrowDown() {
      if (this.selectedItemIndex < this.items.length - 1)
        this.selectedItemIndex++
    },
    onArrowUp() {
      if (this.selectedItemIndex > 0) this.selectedItemIndex--
    },
    onEnter() {
      !this.showOptions
        ? (this.showOptions = true)
        : this.onSelect(
            this.items[this.selectedItemIndex],
            this.selectedItemIndex,
          )
    },
  },
}
