
const spinnerSizes = {
  small: 24,
  medium: 64,
  large: 96,
}

const strokesBySize = {
  small: 4,
  medium: 8,
  large: 12,
}

const dashesBySize = {
  small: '20,100',
  medium: '60,200',
  large: '100,200',
}

export default {
  name: 'DsSpinner',
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    spinnerSize() {
      return spinnerSizes[this.size]
    },
    halfSize() {
      return this.spinnerSize / 2
    },
    strokeWidth() {
      return strokesBySize[this.size]
    },
    strokeDashArray() {
      return dashesBySize[this.size]
    },
    radius() {
      return this.halfSize - this.strokeWidth / 2
    },
  },
}
