
export default {
  name: 'ViewMoreLessByLines',
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    text: {
      type: String,
      default: '',
    },
    lines: {
      type: Number,
      default: 1,
    },
    ctaClasses: {
      type: String,
      default: 'cursor-pointer text-blue-800 hover:underline',
    },
  },
  data() {
    return {
      expanded: this.value || false,
      isButtonVisible: false,
    }
  },
  computed: {
    moreButtonText() {
      return this.expanded ? this.$t('show_less') : this.$t('show_more')
    },
    inlineStyle() {
      if (this.expanded) return 'line-height: 24px;'
      return `line-height: 24px; -webkit-line-clamp: ${this.lines};`
    },
  },
  watch: {
    value(newValue) {
      if (typeof newValue !== 'undefined' && this.expanded !== newValue) {
        this.expand(newValue)
      }
    },
  },
  mounted() {
    const totalLines = this.getLines()
    if (totalLines > this.lines) {
      this.isButtonVisible = true
    }
  },
  methods: {
    getLines() {
      const lineHeight = parseInt(this.$refs.clamp.style.lineHeight)
      const offsetHeight = this.$refs.clamp.scrollHeight
      return Math.ceil(offsetHeight / lineHeight)
    },
    expand(value) {
      this.expanded = value
      this.$emit('input', value)
    },
  },
}
