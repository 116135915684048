
import { format } from 'date-fns'
export default {
  name: 'Report',
  filters: {
    formatDate(val) {
      return format(new Date(val), 'Y-MM-dd hh:mm')
    },
  },
  props: {
    information: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    badge() {
      const severities = {
        low: {
          title: 'vulnerability.reports.badge_minor',
          color: 'bg-[#FCD34D]',
        },
        medium: {
          title: 'vulnerability.reports.badge_medium',
          color: 'bg-[#FB923C]',
        },
        high: {
          title: 'vulnerability.reports.badge_hight',
          color: 'bg-[#DC2626]',
        },
        critical: {
          title: 'vulnerability.reports.badge_critical',
          color: 'bg-[#991B1B]',
        },
      }

      return severities[this.information.severity] || {}
    },
  },
}
